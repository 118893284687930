import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PATHWAYS_GATHERING_RESULTS_URL } from 'config/urls';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import {
  pathwaysJourneyCompletePersonalGoals,
  pathwaysPersonalGoalSubmit,
  usePathwaysPersonalGoals
} from 'sdk';
import * as yup from 'yup';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError, notifyErrors, notifySuccess } from 'utils/notifications';

import Button from 'components/Button';
import InputField from 'components/InputField';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as NextArrow } from './assets/next-arrow.svg';
import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  mainGoal: yup.string().required('Main goal is required.'),
  soThat: yup.string().required('This field is required.'),
  successWillLookLike: yup.string().required('This field is required.')
});

const PersonalGoals = () => {
  const history = useHistory();
  const user = useContext(UserContext);

  const { data: personalGoals = [], isLoading } = usePathwaysPersonalGoals();

  const onSubmit = useCallback(
    async (values) => {
      const data = {
        main_goal: values.mainGoal,
        wanted_result: values.soThat,
        success_explained: values.successWillLookLike
      };
      const { success, errors } = await pathwaysPersonalGoalSubmit({ data });

      if (success) {
        notifySuccess('Personal goal successfully submitted.');

        const selectedOption = personalGoals.find(
          (option) => option.main_goal === values.mainGoal
        );
        trackMixpanelEvent(
          user.id,
          `[Pathways] User selected Personal Goal: "${selectedOption.main_goal}".`,
          { goal: selectedOption }
        );

        const { success: journeyCompleted } =
          await pathwaysJourneyCompletePersonalGoals();

        if (journeyCompleted) {
          history.push(PATHWAYS_GATHERING_RESULTS_URL);
        } else {
          notifyError('Could not set personal goals in the user journey');
        }
      } else {
        notifyErrors(errors);
      }
    },
    [history, personalGoals, user.id]
  );

  const [soThatPlaceholder, setSoThatPlaceholder] = useState('');
  const [successWillLookLIkePlaceholder, setSuccessWillLookLIkePlaceholder] =
    useState('');

  const {
    values: formikValues,
    errors: formikErrors,
    dirty: formikDirty,
    handleSubmit: formikHandleSubmit,
    handleChange: formikHandleChange,
    setFieldValue: formikSetFieldValue,
    isSubmitting: formikIsSubmitting
  } = useFormik({
    initialValues: { mainGoal: '', soThat: '', successWillLookLike: '' },
    validationSchema,
    onSubmit
  });

  const selectRenderValue = (value) => {
    const content = value || 'Select a goal';
    return <Typography className={styles.content}>{content}</Typography>;
  };

  const disableSubmit =
    !isEmpty(formikErrors) || !formikDirty || formikIsSubmitting;

  if (isLoading) return <></>; // TODO: add loader
  return (
    <div className={styles.scrollableContainer}>
      <div className={styles.personalGoals}>
        <div>
          <Typography variant="H-TEXT-1" className={styles.title}>
            Using your responses, we've tailored a set of goals for you. Select
            a goal that <span className={styles.redText}>resonates</span> with
            you, share your motivation, and{' '}
            <span className={styles.redText}>envision success</span> -{' '}
            <span className={styles.redText}>your journey</span>, your way!
          </Typography>
        </div>
        <div>
          <Typography variant="H-TEXT-1" className={styles.label}>
            I want to...
          </Typography>

          <FormControl className={styles.formControl}>
            <Select
              value={formikValues.mainGoal}
              onChange={(event) => {
                const newValue = event.target.value;
                const selectedOption = personalGoals.find(
                  (option) => option.main_goal === newValue
                );
                formikSetFieldValue('mainGoal', selectedOption.main_goal);
                setSoThatPlaceholder(selectedOption.wanted_result_placeholder);
                setSuccessWillLookLIkePlaceholder(
                  selectedOption.success_explained_placeholder
                );
              }}
              displayEmpty
              renderValue={selectRenderValue}
            >
              <MenuItem value="" disabled>
                Select a goal
              </MenuItem>
              {personalGoals.map((personalGoal, index) => (
                <MenuItem
                  key={index}
                  value={personalGoal.main_goal}
                  style={{ whiteSpace: 'normal' }}
                >
                  <Typography className={styles.content}>
                    {personalGoal.main_goal}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography variant="H-TEXT-1" className={styles.label}>
            So that...
          </Typography>

          <InputField
            multiline
            fullWidth
            name="soThat"
            variant="underlined"
            placeholder={soThatPlaceholder}
            value={formikValues.soThat}
            onChange={formikHandleChange}
          />
        </div>

        <div>
          <Typography variant="H-TEXT-1" className={styles.label}>
            And success will look like …
          </Typography>

          <InputField
            multiline
            fullWidth
            name="successWillLookLike"
            variant="underlined"
            placeholder={successWillLookLIkePlaceholder}
            value={formikValues.successWillLookLike}
            onChange={formikHandleChange}
          />
        </div>
      </div>
      <div className={styles.bottomButton}>
        <Button
          disabled={disableSubmit}
          className={styles.submitButton}
          endIcon={<NextArrow className={styles.icon} />}
          onClick={formikHandleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PersonalGoals;
