import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BASE_URL } from 'config/urls';
import { isNull, last } from 'lodash';
import {
  chatbotMessageDisLike,
  chatbotMessageLike,
  useChatbotChatHistory
} from 'sdk';

import { withNavbar } from 'pages/Teachers/shared';
import { notifyErrors } from 'utils/notifications';
import { getConfig } from 'utils/sdk';

import InputChatBot from './components/InputChatBot/index';
import PathwaysChatBotQuery from './components/PathwaysChatBotQuery/index';
import PathwaysChatBotReply from './components/PathwaysChatBotReply/index';
import Typography from 'components/Typography';

import { ReactComponent as BackButtonIcon } from './../PathwaysTodayLesson/assets/back-arrow.svg';
import styles from './styles.module.scss';

const HUMAN_GENERATED = 'Human generated';
const AI_GENERATED = 'AI generated';
const welcomeMessage = `🔥 Welcome to Ember! 🔥

Ember is here to support you with research-backed tools and strategies to help your middle school students succeed in math. Ask Ember for resources, lesson ideas, or strategies to enhance your lessons and keep students engaged!`;

const BackButton = () => {
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };
  return (
    <div className={styles.backButton} onClick={handleBackClick}>
      <BackButtonIcon />
    </div>
  );
};

const PathwaysChatbot = () => {
  const userTimezone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );

  const {
    data: chatHistory,
    isLoading: isLoadingChatHistory,
    refetch: refetchChatHistory
  } = useChatbotChatHistory();

  const [queryMessageForChatbot, setQueryMessageForChatbot] = useState(null);

  const [chatMessageHistory, setChatMessageHistory] = useState([]);

  const [isLoadingChatbotReply, setIsLoadingChatbotReply] = useState(false);

  const chatEndRef = useRef(null);

  useEffect(() => {
    if (chatHistory) {
      setChatMessageHistory(chatHistory);
    }
  }, [chatHistory, setChatMessageHistory]);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessageHistory, isLoadingChatbotReply]);

  const addMessageToChatHistory = (message) => {
    setChatMessageHistory((history) => [
      ...history,
      {
        id: message.id,
        message_type: message.message_type,
        text: message.text
      }
    ]);
  };

  useEffect(() => {
    if (isNull(queryMessageForChatbot)) return;

    const params = getConfig();
    const CHATBOT_URL = `${BASE_URL}/v1/pathways/chat/send/?text=${queryMessageForChatbot}&timezone=${userTimezone}`;
    const eventSource = new EventSource(CHATBOT_URL, params);

    // add empty chatbot response object, which will be loading until response is received
    addMessageToChatHistory({ id: null, text: '', message_type: AI_GENERATED });

    eventSource.onmessage = (event) => {
      const newResponseObject = JSON.parse(event.data);

      setChatMessageHistory((currentChatHistory) => {
        if (currentChatHistory.length === 0) return currentChatHistory;

        const length = currentChatHistory.length;
        const allButLast = currentChatHistory.slice(0, length - 1);
        const lastElement = last(currentChatHistory);

        const updatedLastElement = {
          ...lastElement,
          id: newResponseObject.id,
          text: lastElement.text + newResponseObject.text
        };

        return [...allButLast, updatedLastElement];
      });
    };

    // We're receiving an "error" event when the BE stops sending stream messages
    eventSource.onerror = () => {
      setIsLoadingChatbotReply(false);
      eventSource.close();
    };

    return () => {
      setIsLoadingChatbotReply(false);
      eventSource.close();
    };
  }, [queryMessageForChatbot, userTimezone]);

  const sendMessageToChatbot = async ({ message }) => {
    setIsLoadingChatbotReply(true);
    setQueryMessageForChatbot(message);
  };

  const handleMessageSubmit = ({ message }) => {
    addMessageToChatHistory({
      id: null,
      text: message,
      message_type: HUMAN_GENERATED
    });
    sendMessageToChatbot({ message });
  };

  const handleLike = async (message_id) => {
    const { success, errors } = await chatbotMessageLike({
      chat_message_id: message_id
    });

    if (success) {
      refetchChatHistory();
    } else {
      notifyErrors(errors);
    }
  };

  const handleDislike = async (message_id) => {
    const { success, errors } = await chatbotMessageDisLike({
      chat_message_id: message_id
    });

    if (success) {
      refetchChatHistory();
    } else {
      notifyErrors(errors);
    }
  };

  if (isLoadingChatHistory) return <></>; // TODO: add loader

  return (
    <div className={styles.mainContainer}>
      {/* Initial chatbot ice breaker */}
      <PathwaysChatBotReply message={{ text: welcomeMessage }} />

      {chatMessageHistory.map((message, index) => {
        return (
          <div key={`message-${index}`}>
            {message.message_type === AI_GENERATED && (
              <PathwaysChatBotReply
                message={message}
                showActions={true}
                handleLike={handleLike}
                handleDislike={handleDislike}
              />
            )}
            {message.message_type === HUMAN_GENERATED && (
              <PathwaysChatBotQuery message={message} />
            )}
          </div>
        );
      })}

      <InputChatBot
        onSubmit={handleMessageSubmit}
        isDisabled={isLoadingChatbotReply}
      />
      <div ref={chatEndRef} />
    </div>
  );
};

export default withNavbar(PathwaysChatbot, {
  LeftSubheader: (
    <>
      <BackButton />
      <Typography variant="H-TEXT-1" className={styles.pageTitle}>
        Ask Ember Anything
      </Typography>
    </>
  ),
  sticky: true
});
