import React, { useContext } from 'react';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import ResultExplanationList from './components/ResultExplanationList';
import ScoreExplanationList from './components/ScoreExplanationList';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const Accordion = withStyles({
  root: {
    border: colors.grey6,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: colors.blue8,
    color: colors.blue1,
    borderBottom: colors.grey6,
    marginBottom: -1,
    minHeight: 64,
    '&$expanded': {
      minHeight: 64
    }
  },
  content: {
    justifyContent: 'center',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#F6F5FF'
  }
}))(MuiAccordionDetails);

const ResultsAccordion = ({ results }) => {
  const user = useContext(UserContext);

  return (
    <div className={styles.resultsAccordion}>
      <Accordion square>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          onClick={() => {
            trackMixpanelEvent(
              user.id,
              '[Pathways] User opens "Explanation of your results" section.'
            );
          }}
        >
          <Typography className={styles.resultsAccordionTitle}>
            Explanation of your results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ResultExplanationList results={results} />
        </AccordionDetails>
      </Accordion>

      <Accordion square>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          onClick={() => {
            trackMixpanelEvent(
              user.id,
              '[Pathways] User opens "How is your score calculated" section.'
            );
          }}
        >
          <Typography className={styles.resultsAccordionTitle}>
            How is your score calculated
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ScoreExplanationList results={results} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ResultsAccordion;
