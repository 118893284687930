import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FAMILY_HOME_URL,
  FAMILY_NOTIFICATIONS_URL,
  FAMILY_PLANNER_URL,
  FAMILY_RESOURCES_URL,
  TEACHERS_PERMISSION_DENIED_URL
} from 'config/urls';

import { FamilyPageLayout } from 'pages/Family/shared';

import UnderConstruction from './components/UnderConstruction';

const FAMILY_PAGE_ENABLED = process.env.REACT_APP_FAMILY_APP_ENABLED === 'true';

const Family = () => {
  // TODO: handle mobile screen breakpoints. The layout was based on the StudentPageLayout
  // and this had logic to determine if the content would be limited.
  // This will be addressed in a subsequent issue.
  const limitContentToCenterPortionOfTheScreen = true;

  if (!FAMILY_PAGE_ENABLED) {
    return <Redirect to={TEACHERS_PERMISSION_DENIED_URL} />;
  }

  return (
    <FamilyPageLayout
      limitContentToCenterPortionOfTheScreen={
        limitContentToCenterPortionOfTheScreen
      }
    >
      <Switch>
        <Route path={FAMILY_HOME_URL} component={UnderConstruction} />
        <Route path={FAMILY_NOTIFICATIONS_URL} component={UnderConstruction} />
        <Route path={FAMILY_PLANNER_URL} component={UnderConstruction} />
        <Route path={FAMILY_RESOURCES_URL} component={UnderConstruction} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </FamilyPageLayout>
  );
};

export default Family;
