import React from 'react';
import { withRouter } from 'react-router';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {
  TEACHERS_ASSIGNMENTS_RESULTS_URL,
  TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL
} from 'config/urls';
import { FieldArray, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { bulkUploadAssignmentAssign } from 'sdk';

import { notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';
import { parseParams } from 'utils/urls';

import Button from 'components/Button';
import UserContext from 'components/UserContext';

import { AssignedAssignment, AssignmentForm } from './components';
import { allSectionsList, assignmentAssign, recentSectionsList } from './sdk';
import styles from './styles.module.scss';
import { assignValidationSchema } from './utils';

class AssignAssignment extends React.Component {
  static contextType = UserContext;

  state = {
    assigned: false,
    assignmentData: null,
    recentSections: [],
    allSections: [],
    loading: true
  };

  componentDidMount() {
    // Left like this in order to avoid refactoring in a PR that does another thing.
    this.trackerId = this.props.trackerId;
    this.init();
  }

  async init() {
    await Promise.all([this.fetchRecentSections(), this.fetchAllSections()]);
  }

  fetchRecentSections = async () => {
    const { data, success, errors } = await recentSectionsList();

    if (success) {
      this.setState({ recentSections: data });
    } else {
      notifyErrors(errors);
    }
  };

  fetchAllSections = async () => {
    const { data, success, errors } = await allSectionsList();

    if (success) {
      this.setState({ allSections: data, loading: false });
    } else {
      this.setState({ loading: false });
      notifyErrors(errors);
    }
  };

  onSubmit = async (values, actions) => {
    const { data, success, errors } = await assignmentAssign(this.trackerId, {
      sections_data: _.map(values.sections, (sectionData) => ({
        section_id: sectionData.sectionId,
        ..._.omit(sectionData, 'sectionId')
      }))
    });

    if (success) {
      const { bulkUploadId } = this.props;

      if (!_.isNil(bulkUploadId)) {
        const { success, errors } = await bulkUploadAssignmentAssign({
          bulkUploadId,
          data: { assignment: this.trackerId }
        });

        if (success) {
          this.props.history.push(
            reverse(TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL, {
              bulkUploadId
            })
          );

          this.props.onDialogClose();
        } else {
          notifyErrors(errors);
        }
      } else {
        this.props.onAssignmentAssignSuccess();
        this.setState({ assigned: true, assignmentData: data });
        this.props.nextStep && this.props.nextStep();
      }
    } else {
      notifyErrors(errors);
    }
  };

  defaultOpenDate = () => moment().format();

  defaultDueDate = () => {
    // The default due datetime wanted by specification is two days after today at 3pm.
    let m = moment().add(2, 'days');
    m.set({ h: 15, m: 0 });
    return m.format();
  };

  buildDefaultSection = (sectionId) => ({
    sectionId,
    open: this.defaultOpenDate(),
    due: this.defaultDueDate()
  });

  buildInitialValues = () => {
    if (this.state.loading) {
      return {};
    }

    const { selectedSections } = this.props;

    // If there are sections in the selectedSections prop(used when a user goes back so that we save the state) we set them in the initial values.
    if (!_.isNull(selectedSections)) {
      return { sections: selectedSections };
    }

    // If there're sections in the URL params(and there aren't any other selected sections) we set them in the initial values.
    const searchParams = parseParams(this.props.history.location.search);
    let initialSelectedSectionIds = _.get(
      searchParams,
      'initialSelectedSectionIds',
      []
    );

    if (!_.isArray(initialSelectedSectionIds)) {
      // If it's just a single value - make it an array so we can map over it.
      initialSelectedSectionIds = [initialSelectedSectionIds];
    }

    // Map over the initial selected section ids. If none was set we'll basically be returning an empty array(which is the initial value we're aiming for).
    const initialSections = initialSelectedSectionIds.map((sectionId) =>
      this.buildDefaultSection(_.toInteger(sectionId))
    );

    return { sections: initialSections };
  };

  goToAssignmentResults = () => {
    this.props.history.push(
      reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
        trackerId: this.trackerId
      })
    );
  };

  goBack = (sectionsData) => {
    this.props.storeSelectedSections(sectionsData);
    this.props.prevStep();
  };

  handleResultsClick = async () => {
    this.goToAssignmentResults();

    this.props.onDialogClose();
    this.props.onResultsClick();
  };

  render() {
    const { assigned, assignmentData, recentSections, allSections, loading } =
      this.state;
    const { stepper } = this.props;

    return (
      <Formik
        enableReinitialize
        validationSchema={assignValidationSchema}
        initialValues={this.buildInitialValues()}
        onSubmit={this.onSubmit}
      >
        {({ errors, isSubmitting, handleSubmit, values }) => {
          const disableSubmit =
            isSubmitting ||
            !_.isEmpty(errors) ||
            loading ||
            // There is a racing condition between the initial values of the form and the yup validation.
            // We make sure the Assign button is disabled if this happens.
            _.some(values.sections, { sectionId: '' });

          if (assigned && assignmentData && _.isNil(this.bulkUploadId)) {
            // we hide the share step when creating an assignment from the bulk upload page
            return (
              <>
                <DialogTitle disableTypography className={styles.dialogHeader}>
                  <div />
                  {stepper}
                  <Button
                    color="pink"
                    onClick={this.handleResultsClick}
                    endIcon={<ChevronRightRoundedIcon />}
                  >
                    {_.isNil(this.props.bulkUploadId) ? 'Results' : 'Next'}
                  </Button>
                </DialogTitle>
                <DialogContent>
                  <Box>
                    <AssignedAssignment assignmentData={assignmentData} />
                  </Box>
                </DialogContent>
              </>
            );
          }

          if (!assigned) {
            return (
              <>
                <DialogTitle disableTypography className={styles.dialogHeader}>
                  <Button
                    color="lightGrey"
                    startIcon={<ChevronLeftRoundedIcon />}
                    onClick={() => this.goBack(values.sections)}
                  >
                    Back
                  </Button>

                  {stepper}

                  <Button
                    color="pink"
                    disabled={disableSubmit}
                    endIcon={<ChevronRightRoundedIcon />}
                    onClick={handleSubmit}
                  >
                    Assign
                  </Button>
                </DialogTitle>

                <DialogContent className={styles.dialogContent}>
                  <Box>
                    <FieldArray
                      name="sections"
                      component={(props) => (
                        <AssignmentForm
                          {...props}
                          recentSections={recentSections}
                          allSections={allSections}
                          buildDefaultSection={this.buildDefaultSection}
                        />
                      )}
                    />
                  </Box>
                </DialogContent>
              </>
            );
          }
        }}
      </Formik>
    );
  }
}

export default withRouter(AssignAssignment);
